export function onNextFrame(fn) {
    requestAnimationFrame(() => requestAnimationFrame(() => fn()));
}
export function getSlottedElements(element, slotName) {
    return Array.from(element.querySelectorAll(`:scope > [slot${(slotName === null || slotName === void 0 ? void 0 : slotName.length) > 0 ? `=${slotName}` : ""}]`));
}
export function getDefinedSlots(element) {
    return getSlottedElements(element).map((element) => element.slot);
}
export function isSlotDefined(element, slotName) {
    return !!element.querySelector(`:scope > [slot="${slotName}"]`);
}
export function isSlotUndefined(element, slotName) {
    return !isSlotDefined(element, slotName);
}
export function isDefaultSlotDefined(element) {
    return !!element.querySelector(`:scope >:not([slot])`);
}
// Color calculations
export function toHex(dec) {
    return Math.round(dec).toString(16);
}
export function lighten(hex, factor) {
    const lightenColor = lightenRgb(toRgb(hex), factor);
    return rgbToHex(lightenColor);
}
function lightenRgb(rgb, factor) {
    return rgb.map((value) => 255 - factor * (255 - value));
}
function rgbToHex(rgb) {
    return "#" + rgb.map((val) => toHex(val)).join("");
}
function toRgb(hex) {
    const hexValues = hex.replace("#", "").match(/.{1,2}/g);
    return hexValues.map((hexValue) => parseInt(hexValue, 16));
}
